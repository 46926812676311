import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { message, Collapse } from 'antd'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import photoPackageQuery from '~/graphql/queries/photoPackage.gql'
import updatePhotoPackageMutation from '~/graphql/mutations/updatePhotoPackage.gql'
import PackageForm from './PackageForm'

const PackageEdit = inject('router')(({ router, match: { params: { packageId } } }) => {
  const { data, loading, error } = useQuery(photoPackageQuery, {
    variables: { _id: packageId },
    fetchPolicy: 'network-only'
  })
  const [updatePhotoPackage] = useMutation(updatePhotoPackageMutation)
  if (loading) {
    return 'loading...'
  }
  if (error) {
    return `${error}`
  }
  const { photoPackage: { _id: _, ...record } } = data
  const handleSubmit = async (values) => {
    // TODO: try catch
    const { data } = await updatePhotoPackage({
      variables: {
        _id: packageId,
        ...values
      }
    })
    message.info(`PhotoPackage "${data?.updatePhotoPackage?.record?.name}" updated`, 5)
    router.push('/packages')
  }
  const defaultValues = {
    record
  }
  // console.log({ defaultValues })
  return <>
    <PackageForm onSubmit={handleSubmit} defaultValues={defaultValues} resourceId={packageId} />
    <Collapse style={{ margin: '2em' }}>
      <Collapse.Panel header={`Events (${record?.events?.length})`}>
        <ul>
          {record?.events?.map(event =>
            <li key={event._id}>{event.title} <Link to={`/events/${event._id}/edit`}>edit</Link></li>
          )}
        </ul>
      </Collapse.Panel>
    </Collapse>
  </>
})

export default PackageEdit
