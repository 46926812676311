import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import config from '../config'

const EventAdditionalForm = ({ defaultValues, form, tab }) => {
  const { record } = defaultValues
  const {
    approved,
    createdByUser,
    creditTargets,
    enableVideoSales,
    hidden,
    participantCount,
    photoDownloadMode,
    photoTagFaces,
    photoTagText,
    singleVideoPrice,
    startDate,
    timestamp,
  } = record ?? {}
  const { getFieldValue } = form
  const intl = useIntl()
  return (
    <div
      className={tab === 'additional' ? 'dropdown' : 'hide'}
      style={{
        border: '1px solid #DFDFDF',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTop: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        padding: '8px 16px',
      }}
    >
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['record', 'creditTargets']}
            label={intl.formatMessage({ id: 'app.creditTargets', defaultMessage: 'Credit Targets' })}
            initialValue={creditTargets ?? 'photographer'}
            rules={[{ required: true }]}
          >
            <Select placeholder={intl.formatMessage({ id: 'app.select', defaultMessage: 'Please Select...' })}>
              <Select.Option key='event' value='event'>
                {intl.formatMessage({ id: 'app.event', defaultMessage: 'Event' })}
              </Select.Option>
              <Select.Option key='photographer' value='photographer'>
                {intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['record', 'photoDownloadMode']}
            label={intl.formatMessage({ id: 'app.downloadMode', defaultMessage: 'Download Mode' })}
            initialValue={photoDownloadMode ?? 'priced'}
            rules={[{ required: true, message: 'Please Select Mode!' }]}
          >
            <Select placeholder={intl.formatMessage({ id: 'app.select', defaultMessage: 'Please Select...' })}>
              <Select.Option key='free' value='free'>
                {intl.formatMessage({ id: 'app.free', defaultMessage: 'Free' })}
              </Select.Option>
              <Select.Option key='priced' value='priced'>
                {intl.formatMessage({ id: 'app.priced', defaultMessage: 'Priced' })}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item shouldUpdate>
        {() =>
          getFieldValue(['record', 'photoDownloadMode']) === 'priced' && (
            <>
              <Form.Item
                name={['record', 'enableVideoSales']}
                label={intl.formatMessage({ id: 'app.enableVideoSales', defaultMessage: 'Enable Video Sales' })}
                initialValue={enableVideoSales}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {() =>
                  getFieldValue(['record', 'enableVideoSales']) && (
                    <Form.Item
                      name={['record', 'singleVideoPrice']}
                      label={intl.formatMessage({ id: 'app.singleVideoPrice', defaultMessage: 'Single Video Price' })}
                      initialValue={singleVideoPrice}
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled={moment().isAfter(startDate, 'day')}
                        placeholder={intl.formatMessage({ id: 'app.select', defaultMessage: 'Please Select...' })}
                      >
                        {config.singleVideoPrices.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item
                name={['record', 'timestamp']}
                label={intl.formatMessage({ id: 'app.timestamp', defaultMessage: 'Timestamp' })}
                initialValue={timestamp}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      <Form.Item
        name={['record', 'participantCount']}
        label={intl.formatMessage({ id: 'app.participantCount', defaultMessage: 'Participant Count' })}
        initialValue={participantCount}
      >
        <InputNumber min={0} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name={['record', 'photoTagFaces']}
        label={intl.formatMessage({ id: 'app.faceSearch', defaultMessage: 'Face Search' })}
        initialValue={photoTagFaces}
        valuePropName={'checked'}
      >
        <Switch />
      </Form.Item>
      {config.platform !== 'indo' && (
        <Form.Item
          name={['record', 'photoTagText']}
          label={intl.formatMessage({ id: 'app.bibSearch', defaultMessage: 'BIB Search' })}
          initialValue={photoTagText}
          valuePropName={'checked'}
        >
          <Switch />
        </Form.Item>
      )}
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={12}>
          <Form.Item label={intl.formatMessage({ id: 'app.username', defaultMessage: 'Username' })}>
            <Input defaultValue={createdByUser?.username} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item label={intl.formatMessage({ id: 'app.email', defaultMessage: 'Email' })}>
            <Input defaultValue={createdByUser?.profile.email} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={intl.formatMessage({ id: 'app.phoneNumber', defaultMessage: 'Phone Number' })}>
        <Input defaultValue={createdByUser?.profile.phone} />
      </Form.Item>
      <Form.Item
        name={['record', 'hidden']}
        label={intl.formatMessage({ id: 'app.hidden', defaultMessage: 'Hidden' })}
        initialValue={hidden}
        valuePropName={'checked'}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name={['record', 'approved']}
        label={intl.formatMessage({ id: 'app.approved', defaultMessage: 'Approved' })}
        initialValue={approved}
        valuePropName={'checked'}
      >
        <Switch />
      </Form.Item>
    </div>
  )
}

export { EventAdditionalForm as default }
