import { FileImageFilled } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Upload } from 'antd'
import axios from 'axios'
import loadImage from 'blueimp-load-image'
import path from 'path'
import React, { useState } from 'react'
import url from 'url'
import cloudUploadMutation from '../graphql/mutations/cloudUpload.gql'

const resizeImg = async (file, options = {}, quality = 1) => {
  const { width, height, ...resizeOptions } = options
  const { image, originalWidth, originalHeight } = await loadImage(file, {
    ...resizeOptions,
    orientation: true,
    canvas: true,
  })
  let canvas
  if (width && height) {
    canvas = document.createElement('canvas')
    canvas.width = width ?? resizeOptions.maxWidth ?? originalWidth
    canvas.height = height ?? resizeOptions.maxHeight ?? originalHeight
    canvas.getContext('2d').drawImage(image, 0, 0, width, height)
  } else {
    canvas = image
  }
  return new Promise((resolve) => canvas.toBlob(resolve, file.type, quality))
}

const CloudImageUploader = React.forwardRef(
  (
    {
      children,
      onChange,
      value,
      fileMiddleware,
      afterResize,
      resize = {},
      provider = 'gs',
      path: fullPath,
      fileName,
      accept = 'image/*',
      isTemporary,
      placeholderUrl,
    },
    ref
  ) => {
    const {
      mode, // contain / cover
      ...resizeOptions
    } = resize
    if (mode && !['contain', 'cover'].includes(mode)) {
      throw new Error('RESIZE_MODE_UNSUPPORTED')
    }
    const options = {
      ...resizeOptions,
      contain: mode === 'contain',
      cover: mode === 'cover',
    }
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [upload] = useMutation(cloudUploadMutation)
    const onError = () => setError(true)

    const processFile = async (file) => {
      const _blob = await resizeImg(file, options)
      const blob = afterResize ? afterResize(_blob) : _blob
      const response = await upload({
        variables: {
          provider,
          path: fullPath,
          mimeType: file.type,
          fileName,
          isTemporary,
        },
      })
      const { uploadUrl, downloadUrl } = response?.data?.upload ?? {}
      if (uploadUrl) {
        try {
          if (provider === 'cf') {
            const form = new FormData()
            form.append('file', new File([blob], 'file'))
            await fetch(uploadUrl, {
              method: 'POST',
              body: form,
            })
            setLoading(false)
            onChange(downloadUrl)
            return
          }
          await axios.put(uploadUrl, blob, {
            headers: {
              'Content-Type': file.type,
              ...(provider === 'gs' && {
                'x-goog-acl': 'public-read',
              }),
            },
          })
        } catch (err) {
          console.log(err)
        }

        setLoading(false)
        onChange(downloadUrl)
      }
    }

    const customRequest = async ({ file }) => {
      setError(false)
      setLoading(true)
      if (fileMiddleware) {
        return fileMiddleware(file, processFile)
      }
      return processFile(file)
    }
    const onRemove = () => {
      onChange()
    }
    const fileList = value
      ? [
          {
            uid: value,
            name: path.basename(url.parse(value).pathname),
            status: 'done',
            url: value,
            thumbUrl: value,
          },
        ]
      : []
    const src = value ?? placeholderUrl
    return (
      <Upload.Dragger
        accept={accept}
        customRequest={customRequest}
        fileList={fileList}
        onRemove={onRemove}
        ref={ref}
        showUploadList={false}
        style={{ borderRadius: '8px', background: '#F3F8FE' }}
      >
        {loading ? (
          <div style={{ width: '100%' }} loading={loading}>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <FileImageFilled style={{ fontSize: 50, color: '#00A8DE' }} />
              <div style={{ margin: '5px' }}>Uploading...</div>
            </div>
          </div>
        ) : src && !error ? (
          <div style={{ padding: '0 16px' }}>
            <img alt='' onError={onError} src={src} style={{ background: 'white', maxHeight: '100px' }} />
          </div>
        ) : (
          <>
            {children ?? (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <FileImageFilled style={{ fontSize: 50, color: '#00A8DE' }} />
                <div style={{ margin: '5px' }}>Upload a file or drag and drop JPG</div>
              </div>
            )}
          </>
        )}
      </Upload.Dragger>
    )
  }
)

export default CloudImageUploader
