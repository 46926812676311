import { useMutation } from '@apollo/client'
import { Button, Form, message, Modal } from 'antd'
import { inject } from 'mobx-react'
import moment from 'moment'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import EventAdditionalForm from '../components/EventAdditionalForm'
import EventDocumentForm from '../components/EventDocumentForm'
import EventGeneralForm from '../components/EventGeneralForm'
import EventPackageForm from '../components/EventPackageForm'
import EventPhotographerForm from '../components/EventPhotographerForm'
import UserPermission from '../components/UserPermission'
import config from '../config'
import createPhotoPackageForEventMutation from '../graphql/mutations/createPhotoPackageForEvent.gql'
import updateEventMutation from '../graphql/mutations/updateEvent.gql'

const gridCenter = { alignContent: 'center', alignItems: 'center', display: 'grid', justifyContent: 'center' }
const animation = `
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-footer {
    border: none;
  }
  .dropdown {
    animation: animate 300ms ease-in-out forwards;
  }
  .hide {
    display: none;
  }
  @keyframes animate {
    0% {
      transform: translateY(-50px);
    }
    60% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
`
const tabNext = {
  general: 'photographer',
  photographer: 'package',
  package: 'document',
  document: 'additional',
}

const EventEditForm = compose(
  inject('router'),
  inject('user')
)(({ defaultValues, eventId, router, tab = 'general', user }) => {
  const { record } = defaultValues
  const { coverUrl, photoPackages, singlePhotoPrice, slug, startDate, title } = record ?? {}
  const normalPackages = photoPackages.filter((photoPackage) => photoPackage.packageType !== 'price_fixed')
  const unlimitedPackages = photoPackages.filter((photoPackage) => photoPackage.packageType === 'price_fixed')
  const [form] = Form.useForm()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [summaryModal, setSummaryModal] = useState(false)
  const [updateEvent] = useMutation(updateEventMutation)
  const [createPhotoPackageForEvent] = useMutation(createPhotoPackageForEventMutation)
  const { getFieldsValue } = form
  // const handleClick = (value) => {
  //   router.push(`/events/${eventId}/edit/${value}`)
  // }
  const handleUpdateEvent = async (values) => {
    const { media, packages, record: _record } = values
    const { enableVideoSales, photographerNumber, registrationEndsAt, registrationMethod, registrationStartsAt, startDate, ...record } = _record
    if (tab === 'package') {
      const packageKeys = Object.keys(packages).sort()
      const packageVariables = packageKeys.reduce((prev, curr) => {
        const [type, number] = curr.split('-')
        const amount = Number(packages[`${type}-${number}-amount`])
        if ((type !== 'unlimitedPackage' && prev[Number(number)]) || !amount) {
          return prev
        }
        const minItemCount = packages[`${type}-${number}-minItemCount`] ?? 99
        const packageType = type === 'normalPackage' ? 'discount_fixed' : 'price_fixed'
        const photoSize = 'large'
        const name = `${packageType === 'price_fixed' ? `Unlimited Package` : `Package ${minItemCount}+`} ${photoSize} photos ${amount}${
          packageType === 'discount_percentage' ? '%' : ''
        } for ${singlePhotoPrice}/photo`
        return [...prev, { amount, isDefault: false, minItemCount, name, packageType, photoSize, singlePhotoPrice }]
      }, [])
      const { error: packageError } = await createPhotoPackageForEvent({
        variables: {
          eventId,
          photoPackages: packageVariables,
        },
      })
      if (packageError) {
        message.error(packageError.message, 5)
        setLoading(false)
        return
      }
      message.info(`Event "${title}" updated`, 10)
    } else {
      const { data } = await updateEvent({
        variables: {
          _id: eventId,
          media,
          record: {
            ...record,
            enableVideoSales,
            registrationEndsAt: moment(registrationEndsAt)?.endOf('day'),
            registrationMethod,
            registrationStartsAt: moment(registrationStartsAt)?.startOf('day'),
            photographerNumber: ['auto', 'free'].includes(registrationMethod) ? photographerNumber : null,
            ...(!enableVideoSales && { singleVideoPrice: null }),
            ...(startDate && { startDate: moment(startDate).startOf('day') }),
          },
        },
      })
      const { updateEvent: { record: { title } = {} } = {} } = data ?? {}
      message.info(`Event "${title}" updated`, 10)
    }
  }
  const handleConfirm = async () => {
    try {
      await handleUpdateEvent(getFieldsValue(true))
      router.push(`/events${['photographer'].includes(user.role) ? '?f=created' : ''}`)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
    setSummaryModal(false)
  }
  const handleCancel = () => {
    setLoading(false)
    setSummaryModal(false)
  }
  const handleBack = () => {
    router.history.go(-1)
  }
  const handleFormSubmit = async (values) => {
    setLoading(true)
    if (tab === 'additional' || (tab === 'document' && !['superadmin', 'admin'].includes(user.role))) {
      return setSummaryModal(true)
    }
    try {
      await handleUpdateEvent(values)
      router.push(`/events/${eventId}/edit/${tabNext[tab]}`)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }
  return (
    <div style={{ display: 'grid', justifyItems: 'center', width: '100%' }}>
      <style>{animation}</style>
      <Form
        form={form}
        layout='vertical'
        className='nonPrintDiv'
        onFinish={handleFormSubmit}
        scrollToFirstError={{ behavior: 'smooth' }}
        style={{ margin: '40px 28px', maxWidth: '800px', width: '100%' }}
      >
        <div
          style={{
            display: 'grid',
            background: 'rgba(245, 245, 245, 1)',
            border: 'none',
            borderRadius: '8px',
            gap: '10px',
            padding: '40px',
          }}
        >
          <div style={{ fontSize: '24px', margin: '0 0 10px 0' }}>{title}</div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#F4EBFF',
                border: '1px solid #9E77ED',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'general' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.generalInformation', defaultMessage: 'General Information' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.generalInformation', defaultMessage: 'General Information' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'general' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventGeneralForm defaultValues={defaultValues} form={form} tab={tab} />
          </div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#F4EBFF',
                border: '1px solid #9E77ED',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'photographer' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'photographer' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventPhotographerForm defaultValues={defaultValues} form={form} tab={tab} />
          </div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#F4EBFF',
                border: '1px solid #9E77ED',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'package' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.package', defaultMessage: 'Package' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.package', defaultMessage: 'Package' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'package' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventPackageForm defaultValues={defaultValues} form={form} tab={tab} />
          </div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#FFEBFE',
                border: '1px solid #ED77C4',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'document' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.additionalDocuments', defaultMessage: 'Additional Documents' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.additionalDocuments', defaultMessage: 'Additional Documents' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'document' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventDocumentForm defaultValues={defaultValues} tab={tab} />
          </div>
          <UserPermission grantedTo={['admin', 'operation_admin', 'superadmin']}>
            <div style={{ borderRadius: '12px', display: 'grid' }}>
              <div
                style={{
                  ...gridCenter,
                  background: '#A9D1FF',
                  border: '1px solid #3D94FF',
                  borderBottomLeftRadius: '12px',
                  borderBottomRightRadius: '12px',
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                  fontWeight: '500',
                  gridTemplateColumns: '2fr auto 8fr auto',
                  justifyContent: 'start',
                  padding: '12px',
                  whiteSpace: 'break-spaces',
                  ...(tab === 'additional' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
                }}
              >
                <div>{intl.formatMessage({ id: 'app.additional', defaultMessage: 'Additional' })}</div>
                <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
                <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                  {intl.formatMessage({ id: 'app.additional', defaultMessage: 'Additional' })}
                </div>
                <div style={{ ...gridCenter, ...(tab === 'additional' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
              </div>
              <EventAdditionalForm defaultValues={defaultValues} form={form} tab={tab} />
            </div>
          </UserPermission>
          <div
            style={{
              display: 'grid',
              justifyContent: 'end',
              margin: '10px 0 0 0',
              ...(tab !== 'general' && { gap: '0 10px', gridTemplateColumns: 'auto auto' }),
            }}
          >
            {tab !== 'general' && (
              <Button disabled={loading} loading={loading} onClick={handleBack} style={{ background: 'lightgrey', border: 'none', color: 'grey' }}>
                {intl.formatMessage({ id: 'app.back', defaultMessage: 'Back' })}
              </Button>
            )}
            <Button disabled={loading} loading={loading} htmlType='submit' style={{ background: '#BD188B', border: 'none', color: 'white' }}>
              {tab === 'additional' || (tab === 'document' && !['superadmin', 'admin'].includes(user.role))
                ? intl.formatMessage({ id: 'app.completed', defaultMessage: 'Completed' })
                : intl.formatMessage({ id: 'app.next', defaultMessage: 'Next' })}
            </Button>
          </div>
        </div>
      </Form>
      <Modal
        bodyStyle={{ padding: '40px 40px 20px 40px' }}
        footer={[
          <Button key='ok' onClick={handleConfirm} style={{ background: '#BD188B', border: 'none', color: 'white', margin: '0 15px 15px 0' }}>
            {intl.formatMessage({ id: 'app.ok', defaultMessage: 'OK' })}
          </Button>,
        ]}
        open={summaryModal}
        onCancel={handleCancel}
        style={{ borderRadius: '12px' }}
        width={600}
      >
        <div style={{ display: 'grid', gap: '30px', justifyContent: 'center' }}>
          <img alt='event-cover' src={coverUrl} style={{ maxWidth: '400px', width: '100%' }} />
          <div style={{ display: 'grid', fontWeight: '600', gap: '20px', justifyContent: 'center', textAlign: 'center' }}>
            <div style={{ fontSize: '32px' }}>{title}</div>
            <div style={{ fontSize: '16px' }}>{`https:photo.thai.run/${slug}`}</div>
            <div style={{ fontSize: '20px' }}>{moment(startDate).locale('th').format('D MMMM YYYY')}</div>
            <div
              style={{
                backgroundColor: 'rgba(245, 245, 245, 1)',
                borderRadius: '8px',
                border: '1px solid grey',
                fontSize: '20px',
                fontWeight: '600',
                padding: '15px 40px',
              }}
            >
              <div style={{ lineHeight: '24px', whiteSpace: 'pre-line' }}>{`${intl.formatMessage(
                { id: 'app.singlePhotoPriceSummary', defaultMessage: 'Price {amount}/photo' },
                {
                  amount: intl.formatNumber(singlePhotoPrice, {
                    currency: config.currency,
                    currencyDisplay: 'code',
                    minimumFractionDigits: 0,
                    style: 'currency',
                  }),
                }
              )}${!normalPackages.length ? '' : '\n'}${normalPackages
                .map((normalPackage) =>
                  intl.formatMessage(
                    { id: 'app.normalPackageSummary', defaultMessage: 'Price {amount}/{minItemCount} photos' },
                    {
                      amount: intl.formatNumber(normalPackage.amount, {
                        currency: config.currency,
                        currencyDisplay: 'code',
                        minimumFractionDigits: 0,
                        style: 'currency',
                      }),
                      minItemCount: normalPackage.minItemCount,
                    }
                  )
                )
                .join('\n')}${!unlimitedPackages.length ? '' : '\n'}${unlimitedPackages
                .map((unlimitedPackage) =>
                  intl.formatMessage(
                    { id: 'app.unlimitedPackageSummary', defaultMessage: 'Unlimited Package {amount}' },
                    {
                      amount: intl.formatNumber(unlimitedPackage.amount, {
                        currency: config.currency,
                        currencyDisplay: 'code',
                        minimumFractionDigits: 0,
                        style: 'currency',
                      }),
                    }
                  )
                )
                .join('\n')}`}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
})

export { EventEditForm as default }
