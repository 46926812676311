import { Form, Input, InputNumber, Radio, Button, Switch, Spin, Select } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import config from '../config'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 10,
    },
  },
}
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}

const PackageForm = ({ defaultValues, resourceId, onSubmit }) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { getFieldValue, resetFields } = form
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = async (values) => {
    setLoading(true)
    const { amount, description, displayName, hasUnlimitedItems, isDefault, minimumVideoItems, minItemCount, name, packageType, photoSize, singlePhotoPrice } =
      values.record
    try {
      await onSubmit({
        record: {
          amount: packageType === 'discount_percentage' ? amount : Math.round(amount, 10),
          description,
          displayName,
          hasUnlimitedItems,
          isDefault,
          minimumVideoItems,
          minItemCount,
          name,
          packageType,
          photoSize,
          singlePhotoPrice,
        },
      })
      resetFields()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  return (
    <Form form={form} onFinish={handleFormSubmit} className='nonPrintDiv'>
      <Form.Item
        {...formItemLayout}
        name={['record', 'name']}
        label={intl.formatMessage({ id: 'app.packageName', defaultMessage: 'Packge Name' })}
        initialValue={defaultValues?.record?.name}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'displayName']}
        label={intl.formatMessage({ id: 'app.displayName', defaultMessage: 'Display Name' })}
        initialValue={defaultValues?.record?.displayName}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'description']}
        label={intl.formatMessage({ id: 'app.description', defaultMessage: 'Description' })}
        initialValue={defaultValues?.record?.description}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...formItemLayout}
            name={['record', 'amount']}
            label={intl.formatMessage({ id: 'app.discount', defaultMessage: 'Discount' })}
            initialValue={defaultValues?.record?.amount}
            rules={[{ required: true }]}
          >
            <InputNumber
              min={getFieldValue(['record', 'packageType']) === 'discount_percentage' ? 0 : 1}
              // formatter={value => [
              //   ...(getFieldValue(['record', 'packageType'])?.startsWith('discount') ? ['-'] : []),
              //   value,
              //   ...(getFieldValue(['record', 'packageType'])?.endsWith('percentage') ? ['%'] : [])
              // ].join('')}
              parser={(value) => value.replace(/[A-Za-z]/g, '')}
              precision={getFieldValue(['record', 'packageType']) === 'discount_percentage' ? 2 : 0}
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'packageType']}
        label={intl.formatMessage({ id: 'app.packageType', defaultMessage: 'Package Type' })}
        initialValue={defaultValues?.record?.packageType}
        rules={[{ required: true }]}
      >
        <Radio.Group buttonStyle='solid'>
          <Radio style={radioStyle} value='discount_fixed'>
            discount_fixed
          </Radio>
          <Radio style={radioStyle} value='discount_percentage'>
            discount_percentage
          </Radio>
          <Radio style={radioStyle} value='price_fixed'>
            price_fixed
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'hasUnlimitedItems']}
        label={intl.formatMessage({ id: 'app.unlimitedPackage', defaultMessage: 'Unlimited Package' })}
        initialValue={defaultValues?.record?.hasUnlimitedItems}
        valuePropName={'checked'}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        wrapperCol={{ sm: { span: 3 } }}
        name={['record', 'singlePhotoPrice']}
        label={intl.formatMessage({ id: 'app.singlePhotoPrice', defaultMessage: 'Single Photo Price' })}
        initialValue={defaultValues?.record?.singlePhotoPrice}
        rules={[{ required: true }]}
      >
        <Select placeholder={'Please Select...'}>
          {config.singlePhotoPrices.map((item) => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'minItemCount']}
        label='Photo Minimum Items'
        initialValue={defaultValues?.record?.minItemCount}
        rules={[{ required: true }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      {config.enableVideoSales && (
        <Form.Item
          {...formItemLayout}
          name={['record', 'minimumVideoItems']}
          label='Video Minimum Items'
          initialValue={defaultValues?.record?.minimumVideoItems}
          rules={[{ required: true }]}
        >
          <InputNumber min={0} />
        </Form.Item>
      )}
      <Form.Item
        {...formItemLayout}
        name={['record', 'photoSize']}
        label={intl.formatMessage({ id: 'app.photoSizes', defaultMessage: 'Photo Sizes' })}
        initialValue={defaultValues?.record?.photoSize}
        rules={[{ required: true }]}
      >
        <Radio.Group buttonStyle='solid'>
          <Radio style={radioStyle} value='small'>
            small
          </Radio>
          <Radio style={radioStyle} value='medium'>
            medium
          </Radio>
          <Radio style={radioStyle} value='large'>
            large
          </Radio>
          <Radio style={radioStyle} value='xlarge'>
            xlarge
          </Radio>
          <Radio style={radioStyle} value='xxlarge'>
            xxlarge
          </Radio>
          <Radio style={radioStyle} value='multiple'>
            multiple
          </Radio>
          <Radio style={radioStyle} value='any'>
            any
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['record', 'isDefault']}
        label='default package'
        initialValue={defaultValues?.record?.isDefault}
        valuePropName={'checked'}
      >
        <Switch />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PackageForm
